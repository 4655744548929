@import '../../scss/_color.scss';
@import '../../scss/variables.scss';

.confirm-modal{
    .modal-header{
        border: 0;
        padding: 20px 25px 15px;
        .modal-title{
            font-size: 18px;
        }
    }
    .modal-body{
        padding: 0 10px 25px;
    }
    button{
        @media #{$xsmall-down}{
            height: 35px;
        width: 48%;
           }
        
    }
}