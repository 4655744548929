@font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-thin.eot');
	src: url('../assets/Raleway/raleway-thin.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-thin.woff') format('woff'), url('../assets/Raleway/Raleway-Thin.ttf') format('truetype'), url('../assets/Raleway/raleway-thin.svg#ralewaythin') format('svg');
	font-weight: 100;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-extralight.eot');
	src: url('../assets/Raleway/raleway-extralight.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-extralight.woff') format('woff'), url('../assets/Raleway/Raleway-ExtraLight.ttf') format('truetype'), url('../assets/Raleway/raleway-extralight.svg#ralewayextralight') format('svg');
	font-weight: 200;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-light.eot');
	src: url('../assets/Raleway/raleway-light.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-light.woff') format('woff'), url('../assets/Raleway/Raleway-Light.ttf') format('truetype'), url('../assets/Raleway/raleway-light.svg#ralewaylight') format('svg');
	font-weight: 300;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-regular.eot');
	src: url('../assets/Raleway/raleway-regular.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-regular.woff') format('woff'), url('../assets/Raleway/Raleway-Regular.ttf') format('truetype'), url('../assets/Raleway/raleway-regular.svg#ralewayregular') format('svg');
	font-weight: 400;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-medium.eot');
	src: url('../assets/Raleway/raleway-medium.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-medium.woff') format('woff'), url('../assets/Raleway/Raleway-Medium.ttf') format('truetype'), url('../assets/Raleway/raleway-medium.svg#ralewaymedium') format('svg');
	font-weight: 500;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-semibold.eot');
	src: url('../assets/Raleway/raleway-semibold.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-semibold.woff') format('woff'), url('../assets/Raleway/Raleway-SemiBold.ttf') format('truetype'), url('../assets/Raleway/raleway-semibold.svg#ralewaysemibold') format('svg');
	font-weight: 600;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-bold.eot');
	src: url('../assets/Raleway/raleway-bold.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-bold.woff') format('woff'), url('../assets/Raleway/Raleway-Bold.ttf') format('truetype'), url('../assets/Raleway/raleway-bold.svg#ralewaybold') format('svg');
	font-weight: 700;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-extrabold.eot');
	src: url('../assets/Raleway/raleway-extrabold.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-extrabold.woff') format('woff'), url('../assets/Raleway/Raleway-ExtraBold.ttf') format('truetype'), url('../assets/Raleway/raleway-extrabold.svg#ralewayextrabold') format('svg');
	font-weight: 800;
	font-style: normal;
  }
  @font-face {
	font-family: "Raleway";
	src: url('../assets/Raleway/raleway-heavy.eot');
	src: url('../assets/Raleway/raleway-heavy.eot?#iefix') format('embedded-opentype'), url('../assets/Raleway/raleway-heavy.woff') format('woff'), url('../assets/Raleway/raleway-heavy.ttf') format('truetype'), url('../assets/Raleway/raleway-heavy.svg#ralewayheavy') format('svg');
	font-weight: 900;
	font-style: normal;
  }
  
@font-face {
	font-family: 'playfair';
	src: url('../assets/Playfair_Display/PlayfairDisplay-Regular.ttf');
	font-style: normal;
}
