@import '../../scss/_color.scss';
@import "../../scss/variables.scss";

.notify-modal{
    .modal-header{
        padding: 25px 35px;
        @media #{$small-down}{
            padding: 24px;
          }
        .modal-title{
            color: $text-style4;
            font-size: 30px;
            line-height: 35px;
            font-weight: bold;
            @media #{$small-down}{
                font-size: 18px;
              }
            span{
                img{
                    margin-right: 15px;
                    @media #{$small-down}{
                        margin-right: 5px;
                        width: 20px;
                      }

                }
            }
        }
    }
    .modal-footer {
        padding: 15px 35px;
        @media #{$small-down}{
            padding: 16px 24px;
          }
          & button{
            @media #{$small-down}{
                width: 100%;
              } 
          }
    }
    .modal-body{
        padding: 25px 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        @media #{$small-down}{
            padding: 24px 9px 48px 9px ;
          }
    }
}