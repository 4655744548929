// Font Sizes Variable
$fontsize-10: 10px;
$fontsize-12: 12px;
$fontsize-13: 13px;
$fontsize-14: 14px;
$fontsize-16: 16px;
$fontsize-18: 18px;
$fontsize-20: 20px;
$fontsize-22: 22px;
$fontsize-24: 24px;
$fontsize-32: 32px;
$fontsize-58: 58px;

// Font weight Variables
$fontweight-4: 400;
$fontweight-5: 500;
$fontweight-6: 600;
$fontweight-7: 700;

// Media Queries
// UP
$xsmall-up: "(min-width: 544px)";
$small-up: "(min-width: 768px)";
$tablet-up: "(min-width: 1024px)";
$medium-up: "(min-width: 992px)";
$large-up: "(min-width: 1200px)";
// DOWN
$xsmall-down: "(max-width: 543px)";
$small-down: "(max-width: 767px)";
$tablet-down: "(max-width: 1023px)";
$medium-down: "(max-width: 991px)";
$large-down: "(max-width: 1199px)";
// BETWEEN
$xsmall-only: "(max-width: 543px)";
$small-only: "(min-width: 544px) and (max-width: 767px)";
$medium-only: "(min-width: 768px) and (max-width: 991px)";
$large-only: "(min-width: 992px) and (max-width: 1199px)";
$xlarge-only: "(min-width: 1200px)";
//For Tablet and Smaller Desktop
$tablet-only: "(min-width: 768px) and (max-width: 1200px)";
$large-tablet-only: "(min-width: 1024px) and (max-width: 1400px)";


// Font Weight
$fw-4: 400;
$fw-5: 500;
$fw-6: 600;
$fw-7: 700;
