@import '_fonts.scss';
@import '_color.scss';
@import './_variables.scss';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='checkbox']:checked {
	accent-color: #c15151;
}
input[type='radio']:checked {
	accent-color: #c15151;
}

 input:disabled,
 select:disabled,
 textarea:disabled,
.form-control:disabled,
 .form-control[readonly] {
  background-color: #f1f0f0;
  color: #4F4F4F;
}
.loadingMainpage{
	width: 100%;
	max-width: 100%;
	height: 100%;
    margin: 0;
	color: white;
	.modal-content{
		background-color: transparent;
	    border: none;
	top:50%;
	text-align: center;
	}
	.appLoader{
		color: $text-style4 !important;
	}
}
.calenderPrepend span{
	@media #{$xsmall-down}{
		// display: none;
			padding: 8px;
			}
}
.calenderPrependHome{
	@media #{$xsmall-up}{
		display: none;
	}
}
/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

body {
	background: $text-style1;
	font-family: 'Raleway';
}


.btn:disabled{
    cursor: not-allowed;
    opacity: 0.7;
}
.cursor-pointer {
	cursor: pointer;
}

.width-style1 {
	width: 10%;
}

.spacer-style1 {
	height: 27px;
}

.spacer-style2 {
	height: 34px;
	border-top: 1px solid #d0d0d0;
}

.spacer-style3 {
	height: 12px;
}

.app-footer1 {
	z-index: 1022;
	background-color: $bg-style1;
	color: $text-style1;
	@media #{$xsmall-down}{
		padding:0;
	}
}

.app-header {
	background-color: $bg-style2;
	height: auto;
min-height: 105px;
z-index: 1040 !important;

}

.app-header .nav-item button {
	border-bottom: 0;
}

.app-header .nav-item .nav-link {
	margin-top: 0.75rem;
}

.header-container {
	width: 95%;
	padding-right: 10%;
	padding-left: 15px;
	display: flex;
}

/* .tab-content {
    border: 0px;
    background: transparent;
}

.tab-content .tab-pane {
    padding: 0px;
} */

.user-name {
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	color: $text-style2;
	text-align: left;
}

.role {
	font-size: 12px;
	color: $text-style7;
	font-weight: 700;
	display: block;
	line-height: 14px;
}

.user-icon {
	position: absolute;
	border-radius: 50px;
	left: -30px;
	top: 15px;
}

/* Form */

.filters{
	.form-control{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0.375rem 0.10rem 0.375rem 0.750rem;
	}
}

.form-isInvalid {
	border: 3px solid $text-style3;
	border-radius: 4px;
}

.form-error {
	color: $text-style4;
	font-size: 14px;
	//vertical-align: sub;
	//padding-left: 5px;
	&.large{
	font-size:28px;
	}
}

.form-err-asterik {
	color: $text-style4;
	position: absolute;
	left: 22px;
	bottom: 12px;
	font-size: 18px;
	vertical-align: sub;
	padding-left: 5px;
}
.form-err-mandatory {
    color: $text-style4;
    position: absolute;
    font-size: 28px;
    padding-left: 5px;
    top: -5px;
}
.view-label{
	margin-bottom: 7px;
	line-height: 16px;
	font-weight: $fontweight-6;
}
.view-input {
	line-height: 21px;
	word-break: break-all;
	font-weight: $fontweight-5;
}
input[type="date"]::-webkit-calendar-picker-indicator{
	margin-left: 2px !important;
	padding-left: 0px !important;
}
.ele-underline select {
	border: 0;
    border-bottom: 1px solid $border-style8;
    border-radius: 0;
    padding: 0;
    height: 26px;
    line-height: 16px;
    font-weight: 600;
    color: $text-style15;
    font-size: 16px;
    left: 0;
    margin: 0;
}
.ele-underline label {
	font-size: 16px;
	color: $text-style2;
	font-weight: 600;
    margin-bottom: 15px;
    line-height: 19px;
}

/* Buttons */

.button-style1 {
	font-size: 16px;
	background: $bg-style8;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	color: $text-style1;
	width: 181px;
	height: 47px;
}

.button-style1:hover,
.button-style1:not(:disabled):not(.disabled):active,
.button-style1:not(:disabled):not(.disabled).active,
.show > .button-style1.dropdown-toggle,
.button-style1:focus,
.button-style1.focus,
.button-style1.disabled,
.button-style1:disabled {
	color: $text-style1;
	background: $text-style4;
	border-color: $text-style4;
}

.button-style2 {
	font-size: 16px;
	background: $text-style1;
	border: 1px solid $text-style4;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: $text-style4;
	width: 181px;
	height: 47px;
}

.button-style2:hover,
.button-style2:not(:disabled):not(.disabled):active,
.button-style2:not(:disabled):not(.disabled).active,
.show > .button-style2.dropdown-toggle,
.button-style2:focus,
.button-style2.focus,
.button-style2.disabled,
.button-style2:disabled {
	color: $text-style4;
	background: $text-style1;
	border-color: $text-style4;
}

.button-style3 {
	background: $text-style1;
	border: 1px solid $text-style4;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: $text-style4;
	width: 102px;
	height: 27px;
	padding: 0;
}

.button-style3:hover,
.button-style3:not(:disabled):not(.disabled):active,
.button-style3:not(:disabled):not(.disabled).active,
.show > .button-style3.dropdown-toggle,
.button-style3:focus,
.button-style3.focus,
.button-style3.disabled,
.button-style3:disabled {
	color: $text-style4;
	background: $text-style1;
	border-color: $text-style4;
}

.button-style4 {
	background: $bg-style15;
	border: 1px solid $border-style3;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: $text-style8;
	width: 102px;
	height: 27px;
	padding: 0;
}

.button-style4:hover,
.button-style4:not(:disabled):not(.disabled):active,
.button-style4:not(:disabled):not(.disabled).active,
.show > .button-style4.dropdown-toggle,
.button-style4:focus,
.button-style4.focus,
.button-style4.disabled,
.button-style4:disabled {
	color: $text-style8;
	background: $bg-style15;
	border-color: $border-style3;
}

.button-style5 {
	background: $text-style1;
	border: 1px solid $text-style4;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: $text-style4;
	width: 150px;
	height: 35px;
	padding: 0;
	margin-right: 1rem;
}

.button-style5:hover,
.button-style5:not(:disabled):not(.disabled):active,
.button-style5:not(:disabled):not(.disabled).active,
.show > .button-style5.dropdown-toggle,
.button-style5:focus,
.button-style5.focus,
.button-style5.disabled,
.button-style5:disabled {
	color: $text-style4;
	background: $text-style1;
	border-color: $text-style4;
}


.button-style8:hover,
.button-style8:not(:disabled):not(.disabled):active,
.button-style8:not(:disabled):not(.disabled).active,
.show > .button-style8.dropdown-toggle,
.button-style8:focus,
.button-style8.focus,
.button-style8.disabled,
.button-style8:disabled,
.button-style8:enabled {
	color: none;
	background: none;
	border-color: none;
	box-sizing: border-box;
	box-shadow: none;
	border-radius:none;
}


.button-style6 {
	background: $text-style4;
	border: 1px solid $text-style4;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: $text-style1;
	width: 150px;
	height: 35px;
	padding: 0;
}

.button-style6:hover,
.button-style6:not(:disabled):not(.disabled):active,
.button-style6:not(:disabled):not(.disabled).active,
.show > .button-style6.dropdown-toggle,
.button-style6:focus,
.button-style6.focus,
.button-style6.disabled,
.button-style6:disabled{
	color: $text-style1;
	background: $text-style4;
	border-color: $text-style4;
}


.button-style8 {

	background: white;
	border:none;
    border-color: white;

	border-radius: 4px;
	// color: $text-style4;

	padding: 0;
	margin-right: 1rem;

}

.button-style7 {
	background: $text-style9;
	border: 1px solid $text-style9;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: $text-style1;
	width: 150px;
	height: 35px;
	padding: 0;
}

.button-style7:hover,
.button-style7:not(:disabled):not(.disabled):active,
.button-style7:not(:disabled):not(.disabled).active,
.show > .button-style7.dropdown-toggle,
.button-style7:focus,
.button-style7.focus,
.button-style7.disabled,
.button-style7:disabled {
	color: $text-style1;
	background: $text-style9;
	border-color:$text-style9;
}
.button-style9 {
	background: $text-style4;
	border: 1px solid $text-style4;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: $text-style1;
	width: 200px;
	height: 35px;
	padding: 0;
	@media #{$xsmall-down}{
		width: 100%;
	height: 25px;
padding-left: 5px;
padding-right: 5px;
margin-top: 10px !important;


		font-size: 14px;
	   }
}

.button-style9:hover,
.button-style9:not(:disabled):not(.disabled):active,
.button-style9:not(:disabled):not(.disabled).active,
.show > .button-style9.dropdown-toggle,
.button-style9:focus,
.button-style9.focus,
.button-style9.disabled,
.button-style9:disabled{
	color: $text-style1;
	background: $text-style4;
	border-color: $text-style4;
}

.button-style10 {
	background: $text-style4;
	border: 1px solid $text-style4;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	color: $text-style1;
	width: 100px;
	height: 30px;
	padding: 0;
}

.button-style10:hover,
.button-style10:not(:disabled):not(.disabled):active,
.button-style10:not(:disabled):not(.disabled).active,
.show > .button-style10.dropdown-toggle,
.button-style10:focus,
.button-style10.focus,
.button-style10.disabled,
.button-style10.disabled{
	color: $text-style1;
	background: $text-style4;
	border-color: $text-style4;
}
.btn-link {
	font-weight: 400;
	color: $text-style4;
	text-decoration: none;
}

.btn-link2 {
	background: none;
	border: 0;
	font-weight: 500;
	text-decoration: underline;
	font-size: 14px;
	color: $text-style2;
}
.btn-link3, .btn-link3:hover, .btn-link3:focus {
	background: none;
	color: $text-style4;
	border: none;
	box-shadow: none;
	font-size: 14px;
	font-weight: 600;
	margin: 0 20px;
}
button:focus {
	outline: 0px auto transparent;
}

/* Line Height */
.lh-19 {
	line-height: 19px;
}
.lh-16 {
	line-height: 16px;
}
.lh-23{
	line-height: 23px;
}

/* Font Weight */
.fw-5 {
	font-weight: $fontweight-5;
}
.fw-6 {
	font-weight: $fontweight-6;
}

.fw-7 {
	font-weight: $fontweight-7;
}

/* Font Size */
.fs-12 {
	font-size: $fontsize-12;
}
.fs-13 {
	font-size: $fontsize-13;
}
.fs-14 {
	font-size: $fontsize-14;
}
.fs-16 {
	font-size: $fontsize-16;
}
.fs-18 {
	font-size: $fontsize-18;
}
.fs-22 {
	font-size: $fontsize-22;
}
.fs-24 {
	font-size: $fontsize-24;
}
.fs-32 {
	font-size: $fontsize-32;
}

/* Colors */
.color1 {
	color: $text-style4;
}
.color2 {
	color: $text-style11;
}
.color3{
	color: $text-style7;
}
.color4{
	color: $text-style8;
}
.color5{
	color: $text-style1;
	&:hover{
		color: $text-style1;
	}
}
.color6{
	color: $text-style8;
}

.bg-1{
	background-color: $bg-style10;
}
.bg-2{
	background-color: $bg-style7;
}
.bg-4{
	background-color: $bg-style23;
}
.bg-5{
	background-color: $bg-style25;
}

.bg-3{
	background-color: #FCF0F0;
	padding: 0;
	margin: 0;

	height: 100%;
	width: 100%;


}

.full-width{
	width:100%;
}

/* generic CSS */

.txt-ul {
	text-decoration: underline;
}
.d-block{
	display: block;
}
.e-none {
	pointer-events: none;
}
.break-word {
	word-break: break-all;
}

.css-1rhbuit-multiValue{
	background-color: #fff !important;
    border: 1px solid #C15151;
}
.css-xb97g8 {
	border-left: 1px solid #C15151;
	border-radius: 0px;
}

/* Margin */
.mt-22{
	margin-top: 2.2rem;
}

.app,
app-dashboard,
app-root {
	min-height: 100vh;
}

.link {
	text-decoration: underline;
	cursor: pointer;
}

.Toastify__toast--success {
	background: $text-style5;
	border-radius: 3px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Toastify__toast--error {
	background: $text-style4;
	border-radius: 3px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

a,
a:hover {
	color: $text-style2;
	text-decoration: none;
}
.breadcrumbcontainer{
border-bottom: 1px solid #c8ced3;
}
.breadcrumb {
	border-bottom:none ;
	margin-bottom: 4px;
	@media #{$xsmall-down}{
		margin-bottom: 0px;
	   }
}

.loginModal {
	height: auto;
}

.Toastify__toast-container--top-center {
	top: 40%;
	left: 50%;
}

.header-fixed .app-body {
	margin-top: 110px;
	overflow-x: clip !important;
}
.main{
	margin-left: 0px !important;
}
.main .container-fluid {
	padding: 0 30px;
	@media #{$xsmall-down}{
		padding: 0px;
	   }
}
.navbar-toggler{
	margin-bottom:40px !important;

}

.breadcrumb {
	padding: 0.75rem 0rem;
}
.breadcrumb li:first-child{
	@media #{$xsmall-down}{
		padding-left:0.75rem;
	   }
}
.breadcrumb-item.active {
	color:  #C15151 !important;
  }
.page-view {
	min-height: 700px;
}

.sidebar {
	background: $bg-style4;
	position: inherit !important;
	height:inherit !important;
}
.sidebar-minimizer{
	display: none;
}

.sidebar .nav-link.active {
	color: $text-style2;
	background: $bg-style5;
	// font-weight: 600;
	font-weight: $fontweight-6;
}

.sidebar .nav-link:hover {
	color: $text-style2;
	background: $bg-style5;
}

.sidebar .nav-link,
.sidebar .nav-dropdown.open .nav-link {
	color: $text-style2;
}
.nav-dropdown.open .nav-dropdown-toggle {
    font-weight: $fontweight-6;
}

.sidebar .nav-dropdown.open .nav-link:hover {
	color: $text-style2;
	background: $bg-style5;
}

.sidebar .nav-dropdown.open {
	background: $bg-style6;
}

.sidebar .nav-dropdown.open .nav-link.active {
	color: $text-style2;
	background: $bg-style5;
}
.sidebar1 .nav-link.active {
	color: $text-style2;
	background: $bg-style5;
	// font-weight: 600;
}

.sidebar1 .nav-link:hover {
	color: $text-style2;
	background: $bg-style5;
}

.sidebar1 .nav-link,
.sidebar1 .nav-dropdown.open .nav-link {
	color: $text-style2;
}
.nav-dropdown.open .nav-dropdown-toggle {
    font-weight: $fontweight-6;
}

.sidebar1 .nav-dropdown.open .nav-link:hover {
	color: $text-style2;
	background: $bg-style5;
}

.sidebar1 .nav-dropdown.open {
	background: $bg-style6;
}

.sidebar1 .nav-dropdown.open .nav-link.active {
	color: $text-style2;
	background: $bg-style5;
}


.table-selected-row {
	background-color: $bg-style7;
}

th:focus {
	outline: transparent auto 0px;
}

/* dataTable */
.form-check-input {
	margin-top: 0;
	margin-left: 0;
	position: static;
}

.form-control:disabled,
.form-control[readonly] {

	opacity: 1;
	@media #{$small-down}{
		padding-left: 5px;
		padding-right: 0px;

			}
}

.loading {
	color: $text-style4 !important;
	margin-left: 50%;
	margin-top: 25%;
}
.loadingSmall {
	color: $text-style4 !important;

}
@media #{$xsmall-up}{
.react-bootstrap-table table {
	min-width: 1000px;
}
}

.logo {
	width: 157px;
}
img.NHBLogo {
	outline: none;
	@media #{$xsmall-down}{
		width:127px;
		height: 48px;
			}
}

/* Toggle Switch */

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #C15151;
	background-color: #C15151;
}

.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(193, 81, 81, 0.25);
}

.custom-switch .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
	border-color: #DFDDDD;
	box-shadow: 0 0 0 0.2rem rgba(208, 206, 206, 0.25);
}

.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
	color: #fff;
	background-color: #d26c6c;
	border-color: #C15151;
}

.custom-switch .custom-control-input[disabled] ~ .custom-control-label::before,
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
	background-color: #e9ecef;
	border-color: #e9ecef;
}

.custom-switch .custom-control-label::before {
	width: 30px;
	height: 18px;
	border-radius: 10px;
	background-color: #DFDDDD;
	border: #DFDDDD solid 1px;
}

.custom-switch.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
	border-color: #C15151;
	background-color: #C15151;
}

.custom-switch .custom-control-label::after {
	background-color: #fff;
	width: calc(18px - 4px);
	height: calc(18px - 4px);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
	background-color: #e9ecef;
	border-color: #e9ecef;
}

/* Header Dropdown */
.dropdown-item i {
	display: inline-block;
	width: 20px;
	margin-right: 0px;
	margin-left: 0px;
	color: #c8ced3;
	text-align: center;
}

.dropdown-item {
	padding: 10px;
	font-size: 14px;
	font-weight: 600;
	transition: 0.2s;
	span {
		display: block;
		font-size: 12px;
		font-weight: 500;
	}
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover{
	color: white;
	background-color:#c15151;
}
.notification-wrap button.dropdown-item {
	border-bottom: 1px solid #c8ced3;
}
.individualCount{
	display: inline;
	font-weight: 700;
}
.individualNotification{
	font-weight: 400;
	font-size: 14px;
}
.dd-header {
	background-color: #F1F0F0;
	height: 31px;
	padding: 6px 15px;
	font-size: 12px;
}

.notification-wrap .dd-header {
	font-size: 14px;
}

.dd-item {
	margin-left: 35px;
}

.dd-item-icon {
	position: absolute;
	text-align: center;
	width: 24px;
	&.programme{
		background: #F1F0F0;
        border-radius: 50%;
	}
	&.teacherAccount{
		background: #EEF8E7;
        border-radius: 50%;
	}
	&.staffAccount{
		background: #ECF5FA;
        border-radius: 50%;
	}
}
.dropdown-divider {
	margin: 0.3rem 0;
}

.dd-item i {
	position: absolute;
	right: 10px;
	line-height: 21px;
}

.dd-item img {
	position: absolute;
	right: 10px;
	top: 10px;
}

.badge-icon {
	top: 6px;
	color: #FFF;
	right: 9px;
	height: 16px;
	display: block;
	position: absolute;
	font-size: 9px;
	min-width: 16px;
	background: #A84747;
	text-align: center;
	line-height: 14px;
	border-radius: 10px;
	padding: 0 2px;
	font-weight: 600;
	animation: blinker 2s linear infinite;
}

  @keyframes blinker {
	50% {
	  opacity: 0;
	}
  }

.app-header .nav-item.notification-wrap {
	margin-left: 20px;
}

.notification-wrap .dropdown-menu.dropdown-menu-right {
	width: max-content;
}

/* Custom Checkbox */

.custom-checkbox:checked:after {
    content: "\2714";
    vertical-align: middle;
	text-align: center;
	line-height: 10px;
	position: absolute;
    cursor: pointer;
    font-size: 10px;
    padding:1px;
	height: 13px;
    width: 13px;
    border-radius: 2px;
    box-shadow:none;
    border: 1px solid $border-style6;
    background: $bg-style8;
    color: $text-style1;
}
.custom-checkbox:checked:disabled:after{
	content: "\2714";
    vertical-align: middle;
	text-align: center;
	line-height: 10px;
	position: absolute;
    cursor: pointer;
    font-size: 10px;
    padding:1px;
	height: 13px;
    width: 13px;
    border-radius: 2px;
    box-shadow:none;
    border: 1px solid $border-style6;
    background: $bg-style8;
    color: $text-style1;
}
.custom-checkbox {
    margin-left: 0;
    margin-right: 5px;
}

.nhb-chkbx input[type="checkbox"] {
	display: none;
}

.nhb-chkbx label.nhb-chkbx-lbl {
	display: inline-block;
	cursor: pointer;
	background: url("../assets/img/icons/checkbox-red.svg") no-repeat;
	background-position: 0 2px;
}

.nhb-chkbx label.nhb-chkbx-lbl.grey-check {
	background: url("../assets/img/icons/uncheck.svg") no-repeat;
	background-position: 0 2px;
}

.nhb-chkbx.chkbox-disabled label.nhb-chkbx-lbl.red-check {
	background: url("../assets/img/icons/checkbox-uncheck.svg") no-repeat;
	background-position: 0 2px;
	cursor: default;
}

.nhb-chkbx.chkbox-disabled label.nhb-chkbx-lbl.grey-check {
	background: url("../assets/img/icons/checkbox-uncheck.svg") no-repeat;
	background-position: 0 2px;
	cursor: default;
}
.nhb-chkbx.chkbox-disabled-payment label.nhb-chkbx-lbl.grey-check,
.nhb-chkbx label.nhb-chkbx-lbl.cross-check{
	background: url("../assets/img/icons/Cross.svg") no-repeat;
	background-position: 0 2px;
	cursor: default;
	pointer-events: none;
}

.nhb-chkbx-lbl span {
	margin-left: 20px;
}

.rdw-editor-wrapper {
	border: 1px solid #e4e7ea;
	.rdw-editor-toolbar {
		border: none;
	}
}

.rdw-editor-main {
	padding: 0 10px;
    border-top: 1px solid #e4e7ea;
}

.info-icon {
	margin-top: -3px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
	z-index: 0;
}

textarea.form-control {
	border-color: $border-style7;
	resize: none;
}

.width-100 {
	width: 100%;
}

.posRel {
	position: relative;
}

/* Colored Border Blocks */

.border-block {
	border-left: 0.8px solid $border-style9;
	border-right: 0.8px solid $border-style9;
	border-bottom: 0.8px solid $border-style9;
	min-height: 100px;
	&.red-border{
		border-top: 5px solid $border-style6;
	}
	&.blue-border {
		border-top: 5px solid $border-style10;
		padding: 25px 20px;
	}
	&.yellow-border {
		border-top: 5px solid $border-style8;
		padding: 25px 20px;
	}
	&.green-border {
		border-top: 5px solid $border-style11;
		padding: 25px 20px;
	}
	&.workshop-border {
		border-top: 5px solid $border-style20;
		padding: 25px 20px;
	}
	&.mbl-border{
		border-top : 5px solid $border-style22;
		padding: 25px 20px;
	}
	&.box-shadow {
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	}

	.block-header{
		padding: 15px 30px;
		border-bottom: 1px solid $border-style9;
		font-size: $fontsize-18;
		font-weight: $fontweight-6;
		@media #{$xsmall-down}{
			padding-left: 16px;
			padding-bottom: 16px;
		}
		span{
			font-size: $fontsize-16;
			font-weight: $fontweight-5;
			@media #{$xsmall-down}{
			display:block;
			float:none;
			}
		}
	}
	.block-body{
		.list {
			margin: 0 30px;
			padding: 15px 0;
			border-bottom: 1px solid $border-style9;
			@media #{$xsmall-down}{
				margin:0 16px 0 16px;
				// padding-left: 16px;
			}
			.title{
				font-size: $fontsize-16;
				font-weight: $fontweight-6;
				@media #{$xsmall-down}{
					width: 100%;
					padding-right: 16px;
				}
			}
			.pull-right{
				@media #{$xsmall-down}{
					float: right;
				}
			}
			.sub-title{
				color: $text-style8;
				font-size: $fontsize-16;
				@media #{$xsmall-down}{
					font-size: $fontsize-14;
				}
				img {
					margin-right: 3px;
    				margin-top: -2px;
				}
			}
		}
	}
	.block-footer{
		margin: 0 30px;
		padding: 15px 0;
		@media #{$xsmall-down}{
			margin: 0 16px;
		padding-bottom: 0px;
		}
	}
}
.table-striped tbody tr:nth-of-type(odd){
	background: #FFFFFF;
}
.table-striped tbody tr:nth-of-type(even){
	background: #F9F9F9;
}
.react-bootstrap-table table tr th,.table-striped thead {
	background: #F2F2F2;

}
.react-bootstrap-table table {
	border-bottom: 1px solid #c8ced3;
}
.table-striped thead tr th {
	border-bottom:none ;
}
.pagination{
	.page-item.disabled{
		pointer-events: none;
		.page-link {
			color: $text-style15;
		}
	}
	.page-item{
		.page-link {
			color: $text-style2;
			padding: 0.4rem 0.75rem;
		}
	}
	.page-item.active {
		.page-link {
			background-color: $bg-style8;
			border-color: $border-style6;
			color: $text-style1;
		}
	}
}


.bb-6{
	border: 0px;
	border-bottom: 1px;
	border-style: solid;
	border-color: #DFDDDD;
}
.nhb-progress.progress{
	height: 4px;
	.progress-bar{
		background-color: $bg-style22;
	}
}

.search-bar{
	position: absolute;
	width: 100%;
	bottom: 0;
	top: 100%;
	height: 55px;
	background-color: #fff;
	border-top: 1px solid #eee;
	form{
		width: 100%;
		margin-top: 6px;
		.searchGlobal{
			padding-left: 31px;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border-radius: 0;
			&:focus{
				border-bottom: 1px solid #c8ced3;
				box-shadow: none !important;
				outline: none;
			}
		}
		.MC-refno .icon-magnifier {
			position: absolute;
			top: 12px;
			left: 0px;
		}
	}
}
.booking-rating{
	// box-shadow: 0px 4px 4px rgba(133, 133, 133, 0.25);
    // border-radius: 5px;
	text-align: center;
	.rating-header{
		// background-color: #ECF5FA;
		padding: 20px;
		border-bottom: 1px solid #DFDDDD;
		border-radius: 5px 5px 0 0;
		// margin-top: 20px;
		text-align: center;
		font-weight: 700;
		font-size: 24px;
	}
	.react-stars{
		margin: 0 auto;
		&:focus{
			outline: 0;
		}
	}
	.react-stars span{
		margin-right: 10px;
	}


}
/* Modal */
// .modal-open .modal {
//     text-align: center;
// }
// .modal:before {
//     content: '';
//     display: inline-block;
//     height: 100%;
//     vertical-align: middle;
// }
// .modal.show .modal-dialog {
//     display: inline-block;
//     vertical-align: middle;
// }
// #selected.DatePicker_dateDayItem__8uo6c{
// 	border: none !important;
// }
// #selected.DatePicker_dateDayItem__8uo6c .DatePicker_dateLabel__2tc4t {
//     border-radius: 50% !important;
//     background-color: #c15151;
//     width: 24px;
//     height: 24px;
//     text-align: center;
//     color: #fff;
//     vertical-align: middle;
// }
// .DatePicker_dateLabel__2tc4t {
//     font-size: 13px;
// }
// .DatePicker_buttonWrapper__3DynH {
// 	background-color: #fff !important;
// }
.smileyIcon{
	position: fixed;
  bottom: 15px;
  left: 15px;

  &.box{
	background-color: #ffffff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	width: 340px;
	z-index: 1050;
	&.ratingbox{
		height: 140px;
	  }
	  &.feedbackbox{
		height: 480px;
		background-color: rgb(248, 248, 248);
		.feedbackpadding{
			padding: 2px 10px;
		}
		.comment.form-control {
			height: 60px;
			font-size: 16px;
			resize: none;
		}
	  }
  }
  .react-stars span{
	margin: 0px 7px;
}
}
.plainBtn{
	margin: 0px;
	background-color: transparent;
	padding: 0px;
	border:none;
	// text-align: left;
}
.plainBtn:focus{


		background-color: transparent;
		border-color:#80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(32,168,216,0.25);
		// padding: 2px;

}
.plainBtn:hover{
	background-color: transparent;
	border: none;
}
.feedbackHead{
	background-color: black;
	color: white;
	height: 56px;
	border-radius: 4px;
}
.iconsmiley , .iconsmileyDisplay{
	width:55px;
	height:55px;
	border: 8px solid #333333;
	border-radius: 50%;
	background-color: rgb(255, 219, 102);
	z-index: 1050;
}
.iconsmiley img , .iconsmileyDisplay img{
	width:40px;
	height:40px;
}
.transitionFont{
	display: none;
	font-size: 16px;
	font-weight: 700;
line-height: 35px;
text-align: left;
overflow: hidden;
height: 30px;
}
.iconsmiley:hover{
	color:white;
	width:230px;
	background-color: #333333;
	border-radius: 50px;
transition:width 0.2s ease-in-out ;
& img{
display:none;
}
& .transitionFont{
	display:block;
	padding-left: 45px;
	animation-name: textAnimation;
	animation-duration: 1s;
	}


}
@keyframes textAnimation {
	from {padding-left: 0px;
	opacity: 0;}
	to {padding-left: 45px;
	opacity: 100%;}
  }
.buttonFeedback{
	position: absolute;
	bottom: 15px;
	right: 15px;
}
.ticket-alert.alert{
    margin-bottom: 0px;
}
.title-truncated {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fav-circle {
	border: 1px solid #000;
	border: none;
    border-radius: 50%;
    padding: 0px !important;
    background-color: #f1f1f1;
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
	width:30px;
	height:30px;

}
.fav-circle:hover{
	background:#f1f1f1 !important;
}
.fav-circle:focus {

    border-color: #8ad4ee;
    outline: 0;
    // box-shadow: 0 0 0 0.2rem rgb(32 168 216 / 25%);
}
.fav-btn{
	width: 170px;
	img {
		width: 12px;
	}
}
/* span:focus ,div:focus,a:focus {

    border-color: #8ad4ee !important;
    outline:black solid 1px !important; //

} */
.customSelectDisplay{
	display: inline !important;
	width: 150px;
}