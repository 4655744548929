$text-style1: #ffffff;
$text-style2: #000000;
$text-style3: #dda5a5;
$text-style4: #c15151;
$text-style5: #a1c187;
$text-style6: #b1b1b1;
$text-style7: #A84747;
$text-style8: #848484;
$text-style9: #B39559;
$text-style10: #9A9A9A;
$text-style11: #676767;
$text-style12: #2f353a;
$text-style13: #646464;
$text-style14:#4F4F4F;
$text-style15:#757575;

$border-style1: #3c3c3c;
$border-style2: #545454;
$border-style3: #848484;
$border-style4: #e0e0e0;
$border-style5: #ebebeb;
$border-style6: #c15151;
$border-style7: #c4c4c4;
$border-style8: #B39559;
$border-style9: #DFDDDD;
$border-style10: #C3D9E2;
$border-style11: #DCE9D1;
$border-style12: #ffffff;
$border-style13: #000000;
$border-style14: #A84747;
$border-style15: #c8ced3;
$border-style16: #F1F0F0;
$border-style17:#ECF5FA;
$border-style18:#F1F1F1;
$border-style19:#D0D0D0;
$border-style20: #FBF0CB;
$border-style21: #C89825;
$border-style22: #E1D1A9;
$border-style23: #CFE3EC;
$border-style24: #F4FBFE;

$bg-style1: #3c3c3c;
$bg-style2: #fafafa;
$bg-style3: #d9d9d9;
$bg-style4: #f9f9f9;
$bg-style5: #e3e3e3;
$bg-style6: #f0f0f0;
$bg-style7: #ecf5fa;
$bg-style8: #c15151;
$bg-style9: #717171;
$bg-style10: #f4fbee;
$bg-style11: #F1F0F0;
$bg-style12: #e4e7ea;
$bg-style13:#F8F8F8;
$bg-style14: #B39559;
$bg-style15: #ffffff;
$bg-style16:  #3C763D;
$bg-style17: #31708F;
$bg-style18: #A84747;
$bg-style19: #848484;
$bg-style20:#F5FAFC;
$bg-style21:#AFAFAF;
$bg-style22: #a1c187;
$bg-style23: #FBF0CB;
$bg-style24: #F9FDFF;
$bg-style25: #E1D1A9;
$bg-style26: #F2F2F2;
$bg-style27: #CFE3EC;
$bg-style28: #DCE9D1;
$bg-style29: #F9DADA;
$bg-style30: #FFB30F;
$bg-style31: #E0A8A8;
$bg-style32: #B3B3B3;
$bg-style33: #F4F8F1;
$bg-style34: #F4FBFE;
$bg-style35: #FCD282;
$bg-style36:#997F4C;
$bg-style36:#997F4C;
$bg-style36:#F3F8FB;

$bx-shadow1: 0px 4px 6px rgba(99, 99, 99, 0.25);